import React from 'react';
import { Box, Flex, Heading, Text, Button, Icon, Image, Grid, Avatar, Input, Textarea, Link } from '@chakra-ui/react';
import { AtSignIcon, ChatIcon, TimeIcon, StarIcon, InfoIcon, ExternalLinkIcon, RepeatClockIcon } from '@chakra-ui/icons'; // Chakra UI icons
import FooterLanding from "components/footer/FooterLanding";  // Adjust path based on structure


function HomePage() {
  return (
    <Box bg="white" minH="100vh">
      {/* Menu Bar */}
      <Flex as="nav" align="center" justify="space-between" py="4" px="8" bg="white" borderBottom="1px solid" borderColor="gray.200">
        {/* Logo */}
        <Flex align="center">
          <Image src="https://res.cloudinary.com/subframe/image/upload/v1724586607/uploads/3764/eenq7igt75dvdqlbp9ek.png" alt="Logo" w="60px" h="auto" mr="2" />
          <Heading size="md" color="black"></Heading>
        </Flex>
        {/* Menu Links */}
        <Flex gap="8" align="center">
          <Link href="#/home" color="gray.600" fontWeight="500" _hover={{ color: "black" }}>Home</Link>
          <Link href="#/about" color="gray.600" fontWeight="500" _hover={{ color: "black" }}>About Us</Link>
          <Link href="#/auth" color="gray.600" fontWeight="500" _hover={{ color: "black" }}>Login</Link>
          <Button as="a" href="#/admin" colorScheme="green" size="md">Try Demo</Button>
        </Flex>
      </Flex>

      {/* Hero Section */}
      <Flex direction={['column', 'row']} align="center" py="16" px="4" justify="space-between" maxW="1200px" mx="auto" gap="6">
        {/* Text Section */}
        <Box flex="1" textAlign={['center', 'left']}>
          <Heading fontSize={["32px", "48px"]} fontWeight="700" mb="4" color="black">
            Become excellent. <br /> Be unreasonable. <br /> Introducing Kitchen AI.
          </Heading>
          <Text fontSize={["18px", "24px"]} fontWeight="500" mb="6" color="gray.500">
            kAI assists you build strong customer relationships through data-driven insights, streamlining operations, managing inventory, and more.
          </Text>
          <Button size="lg" colorScheme="green">
            Get started
          </Button>
        </Box>
        {/* Image Section */}
        <Box flex="1" w="full">
          <Image
            src="https://res.cloudinary.com/subframe/image/upload/v1724587331/uploads/3764/ginqab6uml7doxyho6xh.jpg"
            alt="Chef"
            borderRadius="lg"
            maxW="100%"
            objectFit="cover"
          />
        </Box>
      </Flex>

      {/* How We Work Section */}
      <Box py="16" px="4" textAlign="center" maxW="1200px" mx="auto">
        <Heading fontSize={["28px", "36px"]} fontWeight="700" mb="8" color="black">
          How we work
        </Heading>
        <Grid templateColumns={['1fr', 'repeat(3, 1fr)']} gap="6" w="full">
          {/* Integrate Data */}
          <Box bg="gray.50" p="6" borderRadius="md" boxShadow="md">
            <Heading fontSize="24px" fontWeight="600" mb="4" color="black">
              Integrate Data
            </Heading>
            <Text color="gray.600" mb="4">
              We collate all the data that your restaurant produces.
            </Text>
            <Flex align="center" gap="2">
              <InfoIcon />
              <Text>Sales, both online and in-store</Text>
            </Flex>
            <Flex align="center" gap="2">
              <ExternalLinkIcon />
              <Text>Customer reviews left online</Text>
            </Flex>
            <Flex align="center" gap="2">
              <AtSignIcon />
              <Text>Integration with multiple platforms</Text>
            </Flex>
          </Box>

          {/* Analysis */}
          <Box bg="gray.50" p="6" borderRadius="md" boxShadow="md">
            <Heading fontSize="24px" fontWeight="600" mb="4" color="black">
              Analysis
            </Heading>
            <Text color="gray.600" mb="4">
              Experience cutting-edge AI with our best model.
            </Text>
            <Flex align="center" gap="2">
              <TimeIcon />
              <Text>Performs quick data analysis</Text>
            </Flex>
            <Flex align="center" gap="2">
              <InfoIcon />
              <Text>Handles diverse forms of data</Text>
            </Flex>
            <Flex align="center" gap="2">
              <RepeatClockIcon />
              <Text>Real-time analysis</Text>
            </Flex>
          </Box>

          {/* Insights for You */}
          <Box bg="gray.50" p="6" borderRadius="md" boxShadow="md">
            <Heading fontSize="24px" fontWeight="600" mb="4" color="black">
              Insights for you
            </Heading>
            <Text color="gray.600" mb="4">
              We deliver the most important insights for your restaurant.
            </Text>
            <Flex align="center" gap="2">
              <ChatIcon />
              <Text>Points of improvement based on reviews</Text>
            </Flex>
            <Flex align="center" gap="2">
              <StarIcon />
              <Text>Live sales time series</Text>
            </Flex>
            <Flex align="center" gap="2">
              <InfoIcon />
              <Text>Best selling days and items</Text>
            </Flex>
          </Box>
        </Grid>
      </Box>

      {/* Our Story Section */}
      <Box py="24" px="6" bg="gray.100">
        <Flex direction={['column', 'row']} align="center" justify="center" gap="10" maxW="1200px" mx="auto">
          {/* Image Section */}
          <Image
            src="https://res.cloudinary.com/subframe/image/upload/v1725026086/uploads/3764/l6e4bhizkae3ijmsxn5a.png"
            alt="Dashboard"
            borderRadius="lg"
            maxW="500px"
            objectFit="contain"
          />
          {/* Text Section */}
          <Box maxW="448px" textAlign={['center', 'left']}>
            <Heading fontSize="48px" fontWeight="700" mb="6" color="black">
              Our Story
            </Heading>
            <Text fontSize="24px" fontWeight="500" color="gray.500" mb="6">
              We saw a gap for restaurants to improve customer service and cut costs using data.
            </Text>
            <Button size="lg" colorScheme="teal">
              Learn more
            </Button>
          </Box>
        </Flex>
      </Box>

      {/* Testimonials Section */}
      <Box py="24" px="6" textAlign="center" bg="white">
        <ChatIcon w="8" h="8" mb="6" />
        <Heading fontSize="36px" fontWeight="700" mb="6" color="black">
          What people are saying
        </Heading>
        <Text fontSize="24px" color="gray.500" mb="6" maxW="448px" mx="auto">
          Since bringing in Kai we've improved sales by 20%, improved customer reviews by 50%. It gave crucial insights for growth and management.
        </Text>
        <Flex align="center" justify="center" gap="4">
          <Avatar size="lg" src="https://res.cloudinary.com/subframe/image/upload/v1725026284/uploads/3764/nxqdms1ady1r8epmpmvp.png" />
          <Box textAlign="left">
            <Text fontSize="18px" fontWeight="600">Bun & Steak</Text>
            <Text fontSize="16px" color="gray.500">Restaurant Owner</Text>
          </Box>
        </Flex>
      </Box>

      {/* Contact Section */}
      <Box py="32" px="6" textAlign="center" bg="gray.100">
        <ChatIcon w="8" h="8" mb="6" />
        <Heading fontSize="36px" fontWeight="700" mb="4" color="black">
          Get in touch
        </Heading>
        <Text fontSize="24px" color="gray.500" mb="6">
          Request a demo, or ask any questions you have.
        </Text>
        <Flex direction="column" maxW="384px" mx="auto" gap="4">
          <Flex gap="4">
            <Input placeholder="Name" variant="outline" />
            <Input placeholder="Email" variant="outline" />
          </Flex>
          <Textarea placeholder="Message" variant="outline" />
          <Button size="lg" colorScheme="green" w="full">
            Send
          </Button>
        </Flex>
      </Box>
      <FooterLanding />
    </Box>

  );
}

export default HomePage;
