import React from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  List,
  ListItem,
  Image,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

export default function FooterLanding() {
  // Single green background to match the button color
  const greenBg = useColorModeValue("#38A169", "#38A169");
  const textColor = useColorModeValue("white", "white"); // White text for contrast
  const linkColor = useColorModeValue("whiteAlpha.900", "whiteAlpha.900"); // White links

  return (
    <Box bg={greenBg} color={textColor} py="12" px="8">
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="center"
        align={{ base: "center", md: "start" }}
        maxW="1100px"
        mx="auto"
        gap={{ base: "8", md: "6" }} // Balanced gap
      >
        {/* Left Section: Company Logo */}
        <Box textAlign={{ base: "center", md: "left" }} flex="1" mr={{ md: "10" }}>
          <Image
            src="https://res.cloudinary.com/subframe/image/upload/v1724586607/uploads/3764/eenq7igt75dvdqlbp9ek.png"
            alt="Company Logo"
            w="80px"
            h="auto"
            mb="4"
          />
          <Text fontSize="sm">
            <Link href="#" color={linkColor}>
              Terms and privacy
            </Link>
          </Text>
        </Box>

        {/* Middle Section: Products & Company Links */}
        <Flex
          direction="row"
          justify="space-between"
          gap="8"
          flex="2"
        >
          <Box>
            <Text fontSize="lg" fontWeight="bold" mb="4" color={textColor}>
              Products
            </Text>
            <List spacing="2">
              <ListItem>
                <Link color={linkColor} href="#">
                  Business Intelligence
                </Link>
              </ListItem>
              <ListItem>
                <Link color={linkColor} href="#">
                  Inventory & Supply Chain
                </Link>
              </ListItem>
              <ListItem>
                <Link color={linkColor} href="#">
                  Workforce & HR
                </Link>
              </ListItem>
              <ListItem>
                <Link color={linkColor} href="#">
                  Payroll
                </Link>
              </ListItem>
            </List>
          </Box>

          <Box>
            <Text fontSize="lg" fontWeight="bold" mb="4" color={textColor}>
              Company
            </Text>
            <List spacing="2">
              <ListItem>
                <Link color={linkColor} href="#">
                  About Us
                </Link>
              </ListItem>
              <ListItem>
                <Link color={linkColor} href="#">
                  Partners
                </Link>
              </ListItem>
              <ListItem>
                <Link color={linkColor} href="#">
                  Careers
                </Link>
              </ListItem>
              <ListItem>
                <Link color={linkColor} href="#">
                  Success stories
                </Link>
              </ListItem>
            </List>
          </Box>
        </Flex>

        {/* Right Section: Contact Info */}
        <Box textAlign={{ base: "center", md: "left" }} flex="1">
          <Text fontSize="lg" fontWeight="bold" mb="4" color={textColor}>
            Get in touch
          </Text>
          <Text>
            <Link href="mailto:hey@yourcompany.com" color={linkColor}>
              contact.cetor@gmail.com
            </Link>
          </Text>
          <Text mt="2">CETOR Pte. Ltd.</Text>
          <Text>Gibbet Hill Road, Coventry, CV4 7AL</Text>
        </Box>
      </Flex>

      {/* Bottom Section: Social Media and Copyright */}
      <Flex
        direction="column"
        align="center"
        justify="center"
        mt="12"
        borderTop="1px solid white"
        pt="4"
      >
        <Flex gap="4" mb="4">
          <Link href="#" isExternal>
            <Icon as={FaTwitter} boxSize="6" />
          </Link>
          <Link href="#" isExternal>
            <Icon as={FaInstagram} boxSize="6" />
          </Link>
          <Link href="#" isExternal>
            <Icon as={FaLinkedin} boxSize="6" />
          </Link>
        </Flex>

        <Text fontSize="sm" color={textColor}>
          &copy; {new Date().getFullYear()} The Kitchen AI. All rights reserved.
        </Text>
      </Flex>
    </Box>
  );
}
