// Chakra imports
import { Box, Grid, Text, SimpleGrid, Flex, Avatar, Badge, Stat, StatLabel, StatNumber, StatHelpText, StatArrow } from "@chakra-ui/react";
import Card from "components/card/Card.js";  // Adjust the import based on your actual path
import React from "react";

export default function ReviewsFeedback() {
  // Dummy Data
  const reviews = [
    {
      avatar: "/path/to/avatar1.png", // replace with actual path
      name: "John Doe",
      date: "August 24, 2024",
      rating: 4,
      feedback: "Great service and very helpful waiter. Advised me to order the philly cheese steak which was amazing!",
    },
    {
      avatar: "/path/to/avatar2.png", // replace with actual path
      name: "Jane Smith",
      date: "August 20, 2024",
      rating: 5,
      feedback: "Amazing ambience, I love the restaurant owner's taste in food as well as decor.",
    },
    {
      avatar: "/path/to/avatar3.png", // replace with actual path
      name: "Mike Johnson",
      date: "August 18, 2024",
      rating: 2,
      feedback: "The waiter was not very helpful in my case and I ended up having to go to cook in the kitchen myself.",
    },
    // Add more reviews as needed
  ];

  return (
    <Box pt={{ base: "120px", md: "80px", xl: "80px" }}>
      {/* Header Section */}
      <Text fontSize="md" mb="40px">
        Overview of customer feedback and performance insights.
      </Text>
      
      {/* Statistics Summary Section */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="20px" mb="40px">
        <Card>
          <Flex direction="column" align="center">
            <Text fontSize="4xl" fontWeight="bold">
              4.4
            </Text>
            <Text fontSize="md">Average Rating</Text>
          </Flex>
        </Card>
        <Card>
          <Flex direction="column" align="center">
            <Text fontSize="4xl" fontWeight="bold">
              150+
            </Text>
            <Text fontSize="md">Total Reviews</Text>
          </Flex>
        </Card>
        <Card>
          <Flex direction="column" align="center">
            <Text color="green.500" fontSize="4xl" fontWeight="bold">
              +18%
            </Text>
            <Text  fontSize="md">Rating Health</Text>
          </Flex>
        </Card>
      </SimpleGrid>

      {/* Reviews Section */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px" mb="40px">
        {reviews.map((review, index) => (
          <Card key={index}>
            <Flex direction="column">
              <Flex align="center" mb="10px">
                <Avatar src={review.avatar} size="md" mr="10px" />
                <Box>
                  <Text fontWeight="bold">{review.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {review.date}
                  </Text>
                </Box>
                <Badge ml="auto" colorScheme={review.rating >= 4 ? "green" : "red"}>
                  {review.rating} Stars
                </Badge>
              </Flex>
              <Text>{review.feedback}</Text>
            </Flex>
          </Card>
        ))}
      </SimpleGrid>

      {/* Review Analysis Section */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px" mb="40px">
        <Card>
          <Stat>
            <StatLabel>Overall Sentiment</StatLabel>
            <StatNumber>Positive</StatNumber>
            <StatHelpText>
              <StatArrow type="increase" />
              12% from last month
            </StatHelpText>
          </Stat>
        </Card>

        <Card>
          <Text fontSize="lg" fontWeight="bold" mb="10px">
            Alerts
          </Text>
          <Flex direction="column">
            <Box mb="10px">
              <Text fontWeight="bold" color="red.500">
                Alert: Low Rating Detected
              </Text>
              <Text>
                Review by Mike Johnson: "The waiter was not very helpful in my case and I ended up having to go to cook in the kitchen myself." - 2 Stars
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="red.500">
                Alert: Multiple Negative Customer Service Reviews
              </Text>
              <Text>
                We've detected an increase in negative reviews about customer service over the past week. Consider conducting a staff refresher course.
              </Text>
            </Box>
          </Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
