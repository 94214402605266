import React from 'react';

const Home = () => {
    return (
        <div className="container max-w-none flex h-full w-full flex-col items-start gap-6 bg-default-background py-12 overflow-auto">
        <div className="flex w-full flex-col items-center justify-center bg-default-background py-24">
          <div className="flex w-full flex-wrap items-center justify-center gap-4">
            <div className="flex grow shrink-0 basis-0 flex-col items-center justify-center gap-6">
              <div className="flex w-full max-w-[448px] flex-col items-start gap-6 px-4 py-4">
                <div className="flex w-full flex-col items-start gap-4">
                  <span className="w-full text-[48px] font-[700] leading-[48px] text-default-font -tracking-[0.035em]">
                    Let us cook your data!
                  </span>
                  <span className="w-full whitespace-pre-wrap text-[24px] font-[500] leading-[32px] text-subtext-color -tracking-[0.025em]">
                    {
                      "We propel restaurants with AI-driven insights, improving operations, managing inventory, and reducing waste."
                    }
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-heading-1 font-heading-1 text-default-font">
            How we work
          </span>
        </div>
        <div className="flex w-full items-start gap-4">
          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 rounded-md bg-neutral-50 px-6 py-6">
            <div className="flex w-full items-start gap-2">
              <span className="text-heading-3 font-heading-3 text-default-font">
                Integrate data
              </span>
            </div>
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full grow shrink-0 basis-0 text-body font-body text-default-font">
                We collate all the data that your restaurant produces.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-caption font-caption text-subtext-color">
                    Sales, both online and in-store
                  </span>
                </div>
                <div className="flex items-center gap-2">

                  <span className="text-caption font-caption text-subtext-color">
                    Customer reviews left online
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-caption font-caption text-subtext-color">
                    Integration with multiple platforms
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 self-stretch rounded-md bg-neutral-50 px-6 py-6">
            <div className="flex w-full items-start gap-2">
              <span className="text-heading-3 font-heading-3 text-default-font">
                Analysis
              </span>
            </div>
            <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4">
              <span className="w-full grow shrink-0 basis-0 text-body font-body text-default-font">
                Experience cutting-edge AI with our best model.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-caption font-caption text-subtext-color">
                    Performs quick data analysis
                  </span>
                </div>
                <div className="flex items-center gap-2">

                  <span className="text-caption font-caption text-subtext-color">
                    Handles diverse forms of data
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-caption font-caption text-subtext-color">
                    Real-time analysis
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 self-stretch rounded-md bg-neutral-50 px-6 py-6">
            <span className="text-heading-3 font-heading-3 text-default-font">
              Insights for you
            </span>
            <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4">
              <span className="w-full grow shrink-0 basis-0 text-body font-body text-default-font">
                We then deliver the most important insights for your restaurant.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-caption font-caption text-subtext-color">
                    Points of improvement based on reviews
                  </span>
                </div>
                <div className="flex items-center gap-2">

                  <span className="text-caption font-caption text-subtext-color">
                    Live sales time series
                  </span>
                </div>
                <div className="flex items-center gap-2">

                  <span className="text-caption font-caption text-subtext-color">
                    Best selling days and items
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-start gap-4 relative">
          <div className="flex flex-col items-start gap-4 rounded-full border-2 border-solid border-default-background shadow-lg absolute left-4 -bottom-4" />
        </div>
        <div className="flex w-full flex-col items-center justify-center bg-default-background py-24">
          <div className="flex w-full flex-wrap items-center justify-center gap-4">
            <div className="flex min-w-[320px] grow shrink-0 basis-0 flex-col items-center justify-center gap-2 self-stretch">
            </div>
            <div className="flex grow shrink-0 basis-0 flex-col items-center justify-center gap-12 px-4 py-4">
              <div className="flex w-full max-w-[448px] flex-col items-start gap-6 px-4 py-4">
                <div className="flex w-full flex-col items-start gap-4">
                  <span className="w-full text-[48px] font-[700] leading-[48px] text-default-font -tracking-[0.035em]">
                    Our Story
                  </span>
                  <span className="w-full whitespace-pre-wrap text-[24px] font-[500] leading-[32px] text-subtext-color -tracking-[0.025em]">
                    {"We saw a gap for restaurants to improve using data.\n"}
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-12 bg-default-background px-4 py-32">
          <div className="flex w-full flex-col items-center justify-center gap-6">
            <span className="w-full text-[48px] font-[700] leading-[48px] text-default-font text-center -tracking-[0.035em]">
              What people are saying
            </span>
          </div>
          <div className="flex flex-col items-center gap-6">
            <div className="flex flex-col items-center gap-6 px-4 py-4">
              <span className="w-full max-w-[448px] whitespace-pre-wrap text-[24px] font-[400] leading-[32px] text-subtext-color text-center -tracking-[0.025em]">
                {
                  "Since bringing in Kai we've improved sales by 20%, improved customer reviews by 50%. It gave crucial insights for growth and management. "
                }
              </span>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex grow shrink-0 basis-0 flex-col items-start">
                <span className="w-full text-[18px] font-[600] leading-[24px] text-default-font">
                  Bun &amp; Steaks
                </span>
                <span className="w-full text-[16px] font-[400] leading-[24px] text-subtext-color">
                  Restaurant Owner
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-12 bg-default-background px-4 py-32">
          <div className="flex w-full flex-col items-center justify-center gap-12 bg-default-background px-4 py-32">
            <div className="flex w-full flex-col items-center justify-center gap-6">
              <span className="w-full text-[48px] font-[700] leading-[48px] text-default-font text-center -tracking-[0.035em]">
                Get in touch
              </span>
              <span className="w-full whitespace-pre-wrap text-[24px] font-[500] leading-[32px] text-subtext-color text-center -tracking-[0.025em]">
                {"Request a demo, or ask any questions you have."}
              </span>
            </div>
            <div className="flex w-full max-w-[384px] flex-col items-center justify-center gap-6">
              <div className="flex items-center gap-6">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Home;