// Chakra imports
import { Box, Grid, Text, SimpleGrid, Flex, Badge, Stat, StatLabel, StatNumber, StatHelpText, StatArrow } from "@chakra-ui/react";
import Card from "components/card/Card.js";  // Adjust the import based on your actual path
import React from "react";

export default function InventoryDashboard() {
  // Dummy Data
  const inventory = [
    {
      item: "Tomatoes",
      stock: 120,
      unit: "kg",
      status: "In Stock",
      threshold: 50,
      lastUpdated: "August 28, 2024",
    },
    {
      item: "Chicken Breast",
      stock: 20,
      unit: "kg",
      status: "Low Stock",
      threshold: 25,
      lastUpdated: "August 28, 2024",
    },
    {
      item: "Olive Oil",
      stock: 5,
      unit: "liters",
      status: "Critical Stock",
      threshold: 10,
      lastUpdated: "August 27, 2024",
    },
    // Add more inventory items as needed
  ];

  return (
    <Box pt={{ base: "120px", md: "80px", xl: "80px" }}>
      {/* Header Section */}
      <Text fontSize="md" mb="40px">
        Monitor your inventory levels and stay ahead with real-time updates.
      </Text>

      {/* Statistics Summary Section */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="20px" mb="40px">
        <Card>
          <Flex direction="column" align="center">
            <Text fontSize="4xl" fontWeight="bold">
              50
            </Text>
            <Text fontSize="md">Total Items</Text>
          </Flex>
        </Card>
        <Card>
          <Flex direction="column" align="center">
            <Text fontSize="4xl" fontWeight="bold">
              30
            </Text>
            <Text fontSize="md">In Stock</Text>
          </Flex>
        </Card>
        <Card>
          <Flex direction="column" align="center">
            <Text fontSize="4xl" fontWeight="bold">
              5
            </Text>
            <Text fontSize="md">Critical Stock</Text>
          </Flex>
        </Card>
      </SimpleGrid>

      {/* Inventory Items Section */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px" mb="40px">
        {inventory.map((item, index) => (
          <Card key={index}>
            <Flex direction="column">
              <Flex align="center" mb="10px">
                <Box>
                  <Text fontWeight="bold">{item.item}</Text>
                  <Text fontSize="sm" color="gray.500">
                    Last Updated: {item.lastUpdated}
                  </Text>
                </Box>
                <Badge ml="auto" colorScheme={item.status === "Critical Stock" ? "red" : item.status === "Low Stock" ? "orange" : "green"}>
                  {item.status}
                </Badge>
              </Flex>
              <Text>
                {item.stock} {item.unit} remaining
              </Text>
              <Text fontSize="sm" color="gray.500">
                Threshold: {item.threshold} {item.unit}
              </Text>
            </Flex>
          </Card>
        ))}
      </SimpleGrid>

      {/* Inventory Alerts Section */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px" mb="40px">
        <Card>
          <Stat>
            <StatLabel>Stock Levels</StatLabel>
            <StatNumber>Decreasing</StatNumber>
            <StatHelpText>
              <StatArrow type="decrease" />
              8% from last week
            </StatHelpText>
          </Stat>
        </Card>

        <Card>
          <Text fontSize="lg" fontWeight="bold" mb="10px">
            Alerts
          </Text>
          <Flex direction="column">
            <Box mb="10px">
              <Text fontWeight="bold" color="red.500">
                Alert: Critical Stock
              </Text>
              <Text>
                Olive Oil: Only 5 liters remaining. Reorder soon to avoid shortages.
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="orange.500">
                Alert: Low Stock
              </Text>
              <Text>
                Chicken Breast: Only 20 kg left, nearing threshold limit.
              </Text>
            </Box>
          </Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
