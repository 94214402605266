import React from 'react';
import { Box, Flex, Heading, Text, Button, Image, Link } from '@chakra-ui/react';

function aboutus() {
  return (
    <Box bg="white" minH="100vh">
      {/* Menu Bar */}
      <Flex as="nav" align="center" justify="space-between" py="4" px="8" bg="white" borderBottom="1px solid" borderColor="gray.200">
        {/* Logo */}
        <Flex align="center">
          <Image src="https://res.cloudinary.com/subframe/image/upload/v1724586607/uploads/3764/eenq7igt75dvdqlbp9ek.png" alt="Logo" w="40px" mr="2" />
          <Heading size="md" color="black"></Heading>
        </Flex>
        {/* Menu Links */}
        <Flex gap="8" align="center">
          <Link href="/#/home" color="gray.600" fontWeight="500" _hover={{ color: "black" }}>Home</Link>
          <Link href="/#/about" color="gray.600" fontWeight="500" _hover={{ color: "black" }}>About Us</Link>
          <Link href="/#/auth" color="gray.600" fontWeight="500" _hover={{ color: "black" }}>Login</Link>
          <Button as={Link} href="#/admin" colorScheme="green" size="md">Try Demo</Button>
        </Flex>
      </Flex>

      {/* Hero Section */}
      <Flex direction="column" align="center" justify="center" py="24" px="6" gap="6">
        <Heading
          fontSize={["36px", "56px"]}
          fontWeight="600"
          lineHeight={["40px", "62px"]}
          textAlign="center"
          maxW="576px"
          color="black"
        >
          Revolutionising <br /> restaurants with AI
        </Heading>
        <Image
          src="https://res.cloudinary.com/subframe/image/upload/v1724705711/uploads/302/txrjvclpsjkgrwkdaz6x.png"
          alt="AI Revolution"
          maxW="768px"
          objectFit="cover"
          borderRadius="lg"
        />
      </Flex>

      {/* Content Section - Title with Body of Text Together */}
      <Box py="24" px="6" bgGradient="linear(to-b, transparent, gray.100, transparent)">
        <Flex direction="column" align="center" justify="center" gap="12" maxW="1024px" mx="auto">
          <Box textAlign="left" maxW="800px">
            <Heading
              fontSize={["32px", "40px"]}
              fontWeight="600"
              lineHeight={["36px", "44px"]}
              mb="6"
              color="black"
            >
              Transforming Restaurant Operations with AI-Powered Insights
            </Heading>
            <Text
              fontSize="17px"
              fontWeight="400"
              lineHeight="24px"
              color="gray.700"
              mb="6"
            >
              We are four friends who came together with a shared vision: to bring the best of operations research to businesses in a way that truly makes a difference. With a passion for problem-solving and a deep understanding of the challenges faced by modern enterprises, we decided to begin our journey by focusing on an industry close to our hearts—restaurants.
              <br /><br />
              We’ve seen firsthand how crucial it is for restaurants to keep a tight grip on sales, inventory, and labour while striving for peak efficiency. But we also know that success today is measured by so much more: the ability to visualize a sea of metrics, connect with customer feedback, and draw meaningful insights from a variety of data streams. That’s why we developed our solution with these needs in mind.
              <br /><br />
              Introducing Kai, our first iteration of software, your first kitchen AI. Kai is designed to help restaurants not only stay on top of their operations but to excel—by using our home-cooked machine learning models to transform data into actionable insights. With Kai by your side, you’ll be better equipped to navigate the complexities of the restaurant business and take your operations to the next level.
              <br /><br />
              Join us on this exciting journey as we bring the power of advanced operations research to the heart of the kitchen.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default aboutus;
