import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
  VStack,
  HStack,
  IconButton,
  Divider,
  useColorModeValue,
  Textarea,
  Avatar,
  Icon,
} from "@chakra-ui/react";
import { FaMicrophone, FaPaperPlane, FaChartLine } from "react-icons/fa";
import { ChatIcon, QuestionIcon } from "@chakra-ui/icons";

const AIManagerLogbook = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const reportCardBg = useColorModeValue("white", "gray.700");

  return (
    <Box p={6} minH="100vh" bg={bgColor} mt="8"> {/* Added mt="8" for spacing */}
      <Flex direction={["column", "row"]} gap={6}>
        
        {/* Left Panel - AI Today's Report */}
        <Box flex={1} bg={reportCardBg} p={6} borderRadius="lg" shadow="md">
          <Heading size="lg" mb={4}>
            Today's Report
          </Heading>
          <Text fontSize="md" color="gray.600" mb={4}>
            <strong>Hey there!</strong> So, today was a bit of a mixed bag, but we’ve got some takeaways. Revenue-wise, you pulled in around £3,200—not bad, but not quite hitting the usual Friday numbers. The weather might have played a role since fewer people were out and about. 🌧️
          </Text>
          <Text fontSize="md" color="gray.600" mb={4}>
            <strong>What Worked:</strong> The burger deal still crushed it today, as always, and adding fries helped increase the average order value. 🍔 But we didn’t see much uptake on the drink combo. Could be the new soda supplier isn’t hitting the mark with taste preferences? 
          </Text>
          <Text fontSize="md" color="gray.600" mb={4}>
            <strong>Challenges:</strong> Your evening service was slower than usual. Might be worth revisiting the menu or sprucing up your social media push before dinner. Also, inventory was running tight on chicken—just barely made it through. 🚨
          </Text>
          <Text fontSize="md" color="gray.600" mb={4}>
            <strong>This Week:</strong> You've made around £14,000 this week, which is a little below the goal. Fridays are usually your biggest day, but sales dropped by 10% today. We might want to think about why—maybe a mid-week promo can catch some of that lost business?
          </Text>
          <Text fontSize="md" color="gray.600" mb={4}>
            <strong>This Month:</strong> You're trending to hit around £48,000 for the month, a small drop from last month. It seems like your lunch crowd is consistent, but dinner traffic has dipped slightly. Let's explore some evening-specific deals to turn that around.
          </Text>
          <Text fontSize="md" color="gray.600" mb={4}>
            <strong>Looking Ahead:</strong> Think about pushing a weekday lunch combo—maybe a burger and drink bundle to boost slow days. And definitely reorder chicken, especially if we want to avoid tonight's near-miss with running out.
          </Text>
        </Box>

        {/* Center Panel - kAI Live */}
        <Box flex={2} bg={reportCardBg} p={6} borderRadius="lg" shadow="md">
          <Heading size="lg" mb={6}>
            kAI Chat
          </Heading>

          {/* Chat Area */}
          <VStack align="stretch" spacing={4} maxH="400px" overflowY="auto">
            {/* Example User Question */}
            <HStack align="start" justify="flex-end">
              <Box bg="blue.100" p={4} borderRadius="md" shadow="sm" maxW="70%">
                <Text fontWeight="bold">You:</Text>
                <Text>kAI, can you analyze how the price sensitivity of our customers has impacted drink sales, especially with the new soda supplier?</Text>
              </Box>
              <Avatar name="User" />
            </HStack>

            {/* Example AI Response */}
            <HStack align="start">
              <Avatar name="AI Manager" bg="green.400" />
              <Box bg="gray.100" p={4} borderRadius="md" shadow="sm">
                <Text fontWeight="bold">kAI:</Text>
                <Text>
                  Great question! It looks like customers are sensitive to the price of drinks, particularly compared to the burger deal. With the new supplier, sales dropped by 12% compared to last week. This could be a combo of higher pricing and taste preferences not hitting the mark.
                </Text>
                <Text mt={2}>
                  I’d recommend either bundling the drink with an upsell, lowering the price by a few pence, or running a taste test campaign where customers vote on their favorite drink—engagement could help increase sales again!
                </Text>
              </Box>
            </HStack>
          </VStack>

          <Divider my={4} />

          {/* Input Area */}
          <HStack spacing={4} mt={4}>
            <Textarea placeholder="Ask your AI manager something..." flex={1} />
            <IconButton icon={<FaMicrophone />} aria-label="Voice Input" />
            <Button colorScheme="green" rightIcon={<FaPaperPlane />}>
              Send
            </Button>
          </HStack>
        </Box>

        {/* Right Panel - Suggested Actions */}
        <Box flex={1} bg={reportCardBg} p={6} borderRadius="lg" shadow="md">
          <Heading size="lg" mb={4}>
            Actionable Insights
          </Heading>
          <VStack spacing={4} align="stretch">
            <Box p={4} borderRadius="lg" bg={useColorModeValue("red.50", "red.900")} shadow="sm">
              <Text fontWeight="bold">Marketing Tip:</Text>
              <Text>
                A local cafe just launched a "Buy One, Get One Free" coffee deal. We could counter with a weekday lunch bundle—burger + drink—at a special price. 🍔🥤
              </Text>
            </Box>
            <Box p={4} borderRadius="lg" bg={useColorModeValue("teal.50", "teal.900")} shadow="sm">
              <Text fontWeight="bold">Inventory Alert:</Text>
              <Text>We’re almost out of chicken. Let’s reorder ASAP before the weekend rush! 🐔</Text>
            </Box>
            <Box p={4} borderRadius="lg" bg={useColorModeValue("blue.50", "blue.900")} shadow="sm">
              <Text fontWeight="bold">Competitor Insight:</Text>
              <Text>
                Nearby restaurants are boosting sales with their online loyalty programs. Let's consider starting a simple loyalty card for regulars to keep them coming back.
              </Text>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default AIManagerLogbook;
